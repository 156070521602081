import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import { Grid, Row, Col } from 'react-flexbox-grid'
import { Parallax } from 'react-scroll-parallax'

import findImage from '../../utils/findImage'
import Layout from '../../components/Layout'
import WorkTitle from '../../components/WorkTitle'
import Browser from '../../components/Preview'
import Tablet from '../../components/Preview/Tablet'
import Phone from '../../components/Preview/Phone'

import IconsReel from './IconsReel'
import styles from './kfg.module.scss'
import WorkTitleBlock from '../../components/WorkTitleBlock';

export const frontmatter = {
  title: 'KFG Invest Site',
  client: 'KFG Invest',
  type: 'Web design and development',
  path: '/works/web/kfg-invest-site/',
  date: '2015-04-15',
  image: 'kfg-site-demo',
  favorite: true,
  work_type: 'Site',
  related: '/works/identity/kfg-invest-style/',
}

/*
const related = [
  {
    title: frontmatter.work_type,
    href: frontmatter.path,
  },
  {
    title: 'Identity',
    href: frontmatter.related,
  },
]*/

export default ({ location, data }) => {
  const images = data.images.edges

  return (
    <Layout location={location}>
      <Helmet title={frontmatter.title}>
        <body className={styles.page} />
      </Helmet>

      <WorkTitle title={frontmatter.title}  />

      <Grid>
        <p className="aligncenter text-center">
          Redesign the real estate site to streamline the booking and buying
          process.
        </p>
      </Grid>

      <Grid>
        <Row bottom="xs">
          <Col xs={9}>
            <Tablet
              fluid={findImage(images, 'kfg-home-ipad')}
              className={styles.heroTablet}
              isDark
              shadow
            />
          </Col>
          <Col xs={3}>
            <Phone
              fluid={findImage(images, 'kfg-property-phone')}
              className={styles.heroPhone}
              isDark
              shadow
            />
          </Col>
        </Row>
      </Grid>

      <IconsReel />

      <div className={styles.bg}>
        <Grid>
          <Row>
            <Col sm={8} md={6} mdOffset={0}>
              <h3>The Problem</h3>
              <p>
                The old website was lacking the focus on the property search and
                was cluttered with marketing and general information.
              </p>

              <p>
                Property cards were not big enough &mdash; often users did not
                notice the small list on&nbsp;the&nbsp;right. There was no
                searching and filtering, so users had to go through the long
                lists of properties to find what they were looking for.
              </p>

              <p>
                The site was not responsive and whopping 30% of users (according
                to Google Analytics at that time) were getting sub-par
                experience.
              </p>
            </Col>
            <Col xs={8} sm={4} md={4} xsOffset={2} smOffset={0} mdOffset={1}>
              <Parallax y={[10, -20]}>
                <Browser
                  fluid={findImage(images, 'kfg-site-old')}
                  className={styles.old}
                  url="Old Website"
                  isSmall
                />
              </Parallax>
              <br />
              <br />
            </Col>
          </Row>
        </Grid>

        <p className="aligncenter text-center" style={{ marginBottom: '5em' }}>
          Site is responsive and works on every modern device.
        </p>

        <Grid fluid>
          <Row>
            <Col xs={7} sm={4}>
              <Parallax y={[20, -20]}>
                <Tablet
                  className={styles.col1}
                  fluid={findImage(images, 'kfg-site-main-menu')}
                />
              </Parallax>
            </Col>
            <Col xs={7} sm={4} xsOffset={2} smOffset={0}>
              <Tablet
                className={styles.col2}
                fluid={findImage(images, 'kfg-site-property')}
              />
            </Col>
            <Col xs={7} sm={4} xsOffset={5} smOffset={0}>
              <Parallax y={[-20, 20]}>
                <Tablet
                  className={styles.col3}
                  fluid={findImage(images, 'kfg-site-favourites')}
                />
              </Parallax>
            </Col>
          </Row>
        </Grid>

        <br />
        <br />
        
        <WorkTitleBlock {...frontmatter} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    images: allFile(filter: { relativePath: { regex: "^/kfg/i/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

/*
<abbr>KFG</abbr> Invest builds, sells and rents the property in south Turkey. New website helps their customers find the place they are looking for.

What problem did this project solve?

Redesign the real estate website to streamline the booking and buying process by allowing users to find the right property for them.


What process did you use?



What were the outcome and results?

*/
