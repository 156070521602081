import React, { useState, useEffect, useCallback, useRef } from 'react'

import styles from './kfg.module.scss'

const iconsCount = 7
const xSpread = 4.5
let initialIcons = []

for (var i = 1; i < iconsCount; i++) {
  const posX =
    i < iconsCount / 2
      ? { left: xSpread * (i - 1) + '%' }
      : { right: xSpread * (iconsCount - i - 1) + '%' }
  const posY = Math.floor(Math.random() * 120) - 80

  initialIcons.push({
    src: require('./kfg-icons/icon-' + i + '.svg'),
    originalPosition: {
      x: posX,
      y: posY,
    },
    x: posX,
    y: posY,
    zIndex: 3,
  })
}

const IconsReel = () => {
  const [icons, updateIcons] = useState(initialIcons)
  const containerRef = useRef()
  
  const handleScroll = useCallback( (event) => {
    if (!containerRef.current) return false

    const top = containerRef.current.getBoundingClientRect().top
    const height = containerRef.current.getBoundingClientRect().height

    if (top + height / 2 >= 0) {
      const scrollTop = event.srcElement.scrollingElement.scrollTop
      updatePosition(scrollTop)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])


  const updatePosition = scrollTop => {
    const path = Math.floor(scrollTop * 0.2)
    const height = 88
    const newIcons = initialIcons.map(icon => {
      const top = icon.originalPosition.y
      if (path > top + height) {
        // Back
        icon.y = path - top - height * 2
        icon.zIndex = 1
      } else {
        // Front
        icon.y = top - path
        icon.zIndex = 3
      }
      return icon
    })

    updateIcons(newIcons)
  }

  return (
    <div className={styles.icons} ref={containerRef}>
      <div className={styles.iconsList}>
        {icons.map((icon, index) => (
          <div
            key={index}
            className={styles.icon}
            style={{
              zIndex: icon.zIndex,
              transform: 'translateY(' + icon.y + 'px)',
              ...icon.x,
            }}
          >
            <img src={icon.src} alt="" />
          </div>
        ))}
      </div>
      <div className={styles.iconsBg} />
    </div>
  )
}

export default IconsReel
